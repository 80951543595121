import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Lexend\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"lexend\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_components/Analytics.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/fonts.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/index.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/global.scss");
;
import(/* webpackMode: "eager" */ "/app/src/styles/iubenda.scss");
